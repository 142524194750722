import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Palette from "@material-ui/icons/Palette";

import Build from "@material-ui/icons/Build";
// core components

import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-kit-react.js";
import Success from "components/Typography/Success.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import Badge from "components/Badge/Badge.js";
import Tooltip from "@material-ui/core/Tooltip";

import profile from "assets/img/faces/mohamed.png";
import rhodes from "assets/img/rhodes.jpeg";
import gafsa2 from "assets/img/gafsa2.jpeg";
import ny2 from "assets/img/ny2.jpeg";
import austin from "assets/img/austin.jpeg";
import carmel1 from "assets/img/carmel1.jpeg";
import carmel4 from "assets/img/carmel4.jpeg";
import beja from "assets/img/beja.jpeg";
import testour1 from "assets/img/testour1.jpeg";
import tunis from "assets/img/tunis.jpeg";
import tozeur from "assets/img/tozeur.jpeg";
import beja2 from "assets/img/beja2.jpeg";
import pdf from "assets/cv/mohamedresume.pdf";

import styles1 from "assets/jss/material-kit-react/views/profilePage.js";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

const styles = {
  ...styles1,
  ...imagesStyles,
  cardTitle
};

const useStyles = makeStyles(styles);

export default function ProfilePage() {
  const classes = useStyles();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      {/* <Header
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      /> */}
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Mohamed Mohamed</h3>
                    <h6>Entrepreneur & Site Reliability Engineer</h6>
                    <Button justIcon link className={classes.margin5}>
                      <a
                        href="https://twitter.com/MohamedMohame_d"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className={"fab fa-twitter"} />
                      </a>
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <a
                        href="https://www.linkedin.com/in/mohamedmohamedphd/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className={"fab fa-linkedin"} />
                      </a>
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <a
                        href="https://www.facebook.com/mohamed.mohamedm"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className={"fab fa-facebook"} />
                      </a>
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <a
                        href="https://github.com/midoblgsm"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className={"fab fa-github"} />
                      </a>
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                An SRE expert with 8 YoE in building well architected platforms
                in large scale systems from the ground up. Extensive practice of
                Agile, TDD, Pair Programming and CI/CD management in large code
                base and OSS. A proven history of patents and papers in top
                venues. Proactive, self starter, methodical and outcome driven
                with capabilities to build efficient collaborations.
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem
                lg={10}
                xs={12}
                sm={12}
                md={10}
                className={classes.navWrapper}
              >
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Engineering",
                      tabIcon: Build,
                      tabContent: (
                        <>
                          <GridContainer justify="center">
                            <GridItem lg={4} xs={12} sm={12} md={4}>
                              <div style={{ textAlign: "left" }}>
                                <h3 style={{ marginLeft: "10%" }}>
                                  Apple<Badge color="danger">1 year</Badge>
                                  <Badge color="info">Present</Badge>
                                </h3>

                                <ul>
                                  <li>Platform SRE</li>
                                  <li>Mail SRE</li>
                                  <li>Postmortem Advisor</li>
                                </ul>
                              </div>
                            </GridItem>
                            <GridItem lg={4} xs={12} sm={12} md={4}>
                              <div style={{ textAlign: "left" }}>
                                <h3 style={{ marginLeft: "10%" }}>
                                  LGE<Badge color="success">1 year</Badge>
                                </h3>

                                <ul>
                                  <li>Data Marketplace</li>
                                  <li>AWS Admin</li>
                                  <li>Kubernets Admin</li>
                                  <li>CICD Admin</li>
                                </ul>
                              </div>
                            </GridItem>
                            <GridItem lg={4} xs={12} sm={12} md={4}>
                              <div style={{ textAlign: "left" }}>
                                <h3 style={{ marginLeft: "10%" }}>
                                  IBM<Badge color="info">4 years</Badge>
                                </h3>

                                <ul>
                                  <li>Kubernetes Persistent Storage</li>
                                  <li>Kubernetes HA automation</li>
                                  <li>CloudFoundry Persistence</li>
                                  <li>SLA Platform</li>
                                </ul>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem lg={12} xs={12} sm={12} md={12}>
                              <Card>
                                <CardBody
                                  style={{
                                    textAlign: "left",
                                    backgroundColor: "#f0f1f2"
                                  }}
                                >
                                  <h4 className={classes.cardTitle}>Skills</h4>
                                  <Success>Programming Languages:</Success>Java,
                                  <b> Go</b> - REST, gRPC, proto, swagger -
                                  <b> React</b> & React Native
                                  <Success>Technologies:</Success>
                                  <b>Kubernetes</b>, Cloud Foundry, Docker,
                                  <b> AWS</b>
                                  (ECS, EKS, Lambda), GCP, Hyperledger Fabric
                                  <Success> Programming Methodologies:</Success>
                                  <b>TDD</b>, BDD, Agile, Pair Programming,
                                  Design Thinking.
                                </CardBody>
                                <a
                                  href={pdf}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Download Resume
                                </a>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      tabButton: "Photos",
                      tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <Tooltip
                              id="tooltip-top"
                              title="Beja"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="beja"
                                src={beja}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Gafsa"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="gafsa"
                                src={gafsa2}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Tunis"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="tunis"
                                src={tunis}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Tozeur"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="tozeur"
                                src={tozeur}
                                className={navImageClasses}
                              />
                            </Tooltip>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Tooltip
                              id="tooltip-top"
                              title="Testour"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="testour"
                                src={testour1}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Carmel"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="carmel"
                                src={carmel1}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="New York"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="ny"
                                src={ny2}
                                className={navImageClasses}
                              />
                            </Tooltip>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Tooltip
                              id="tooltip-top"
                              title="Carmel"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="carmel"
                                src={carmel4}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Rhodes"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="rhodes"
                                src={rhodes}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Austin"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="austin"
                                src={austin}
                                className={navImageClasses}
                              />
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Beja"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img
                                alt="beja"
                                src={beja2}
                                className={navImageClasses}
                              />
                            </Tooltip>
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Other",
                      tabIcon: Dashboard,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            Co-Founder of{" "}
                            <a
                              href="https://www.horizon-university.tn"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Horizon University
                            </a>
                            <p>
                              A private university of excellency specialized in
                              cutting edge Information Technology studies and
                              located in Tunisia.
                            </p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            Co-Founder of{" "}
                            <a
                              href="https://www.carthage-foods.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Carthage Foods
                            </a>
                            <p>
                              A US based import company specialized in superior
                              quality food items such as Extra Virgin Olive Oil
                              and Dates.
                            </p>
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
